/** ----- Modules ----- */
import React from "react";
/** ----- Redux ----- */
import { useAppSelector } from "../../redux/store";
/** ----- Public ----- */
import aboutImage from "../../assets/home/about/about-image.png";
/** ----- Style ----- */
import "../../styles/components/home/about.sass";

/** ----- Code ----- */
export const About = () => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );

  const { about } = data[language].components.home;

  return (
    <div className="about">
      <div className="about-inner">
        <div className="about-image">
          <img src={aboutImage} alt="about" />
        </div>
        <div className="about-container">
          <div className="about-container-title">
            <span>{about.title}</span>
          </div>
          <div className="about-container-description">
            {about.description.map((prg: string) => {
              return <p className="description-paragraph">{prg}</p>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
