/** ----- Modules ----- */
import React from "react";
/** ----- Components ----- */
import { SubmitApp } from "../../components/home/submit";
import { ProductPreview } from "../../components/products/preview";
import { Tasks } from "../../components/products/tasks";
import { Advantages } from "../../components/products/advantages";
import { Settings } from "../../components/products/settings";
import { Industries } from "../../components/products/industries";
/** ----- Redux ----- */
import { useAppSelector } from "../../redux/store";
/** ----- Public ----- */
import qmsImage from "../../assets/home/products/qms.png";
/** ----- Styles ----- */
import "../../styles/pages/qms.sass";

/** ----- Code ----- */
export const QMS = () => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );

  const { qms } = data[language].components.products;

  return (
    <>
      <ProductPreview image={qmsImage} language={qms} requestCall={true} />
      <Tasks language={qms.tasks} />
      <SubmitApp />
      <Advantages language={qms.advantages} />
      <Settings language={qms.settings} />
      <SubmitApp />
      <Industries language={qms.industries} />
    </>
  );
};
