/** ----- Modules ----- */
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
/** ----- Components ----- */
import { ConfigProvider } from "antd";
import { App } from "./app";
/** ----- Redux ----- */
import { store } from "./redux/store";

/** ----- Code ----- */
const rootElem = document.getElementById("root");

if (rootElem) {
  const root = ReactDOM.createRoot(rootElem);

  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Montserrat",
            },
          }}
        ></ConfigProvider>
        <App />
      </Provider>
    </BrowserRouter>
  );
}
