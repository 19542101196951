/** ----- Modules ----- */
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
/** ----- Components ----- */
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Loader } from "../components/loader";
import { LanguageError } from "../components/error/language";
/** ----- Types ----- */
import { IStatus } from "../redux/types/status";
/** ----- Redux ----- */
import {
  fetchLanguagePack,
  readCookieLanguage,
} from "../redux/slices/language";
import { useAppDispatch, useAppSelector } from "../redux/store";
/** ----- Styles ----- */
import "../styles/layouts/main.sass";

/** ----- Code ----- */
export const MainLayout = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.languagePackReducer);

  useEffect(() => {
    dispatch(fetchLanguagePack());
    dispatch(readCookieLanguage());
  }, []);

  if (status === IStatus.error) {
    return <LanguageError />;
  } else if (status === IStatus.idle || status === IStatus.loading) {
    return <Loader />;
  }

  return (
    <div className="main-layout">
      <Header />
      <div className="main-layout-outlet">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
