/** ----- Modules ----- */
import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
/** ----- Component */
// import { Loader } from "./components/loader";
/** ----- Pages ----- */
import { Home } from "./pages/home";
/** ----- Layouts ----- */
import { MainLayout } from "./layouts/main";
/** ----- Styles ----- */
import "./styles/index.sass";

/** ----- Code ----- */
// const ProductsLayout = React.lazy(() => import("./layouts/products"));
// const QMS = React.lazy(() => import("./pages/products/qms"));

// const EquipmentLayout = React.lazy(() => import("./layouts/equipment"));
// const Terminals = React.lazy(() => import("./pages/equipment/terminals"));
// const Printers = React.lazy(() => import("./pages/equipment/printers"));
// const EquipmentAdditional = React.lazy(
//   () => import("./pages/equipment/additional")
// );
// const Terminal = React.lazy(() => import("./pages/equipment/terminal-card"));
// const Printer = React.lazy(() => import("./pages/equipment/printer-card"));
// const AdditionCard = React.lazy(
//   () => import("./pages/equipment/additional-card")
// );

// const RequestCall = React.lazy(() => import("./pages/request-call"));
// const TechnicalRequest = React.lazy(() => import("./pages/technical-request"));

// const NotFoundPage = React.lazy(() => import("./pages/not-found"));

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="" element={<Home />} />
        {/* <Route
          path="products"
          element={
            <Suspense fallback={<Loader />}>
              <ProductsLayout />
            </Suspense>
          }
        >
          <Route path="" element={<Navigate to={"qms"} />} />
          <Route
            path="qms"
            element={
              <Suspense fallback={<Loader />}>
                <QMS />
              </Suspense>
            }
          />
        </Route> */}

        {/* <Route
          path="equipment"
          element={
            <Suspense fallback={<Loader />}>
              <EquipmentLayout />
            </Suspense>
          }
        >
          <Route path="" element={<Navigate to={"terminals"} />} />
          <Route
            path="terminals"
            element={
              <Suspense fallback={<Loader />}>
                <Terminals />
              </Suspense>
            }
          />
          <Route
            path="terminals/:id"
            element={
              <Suspense fallback={<Loader />}>
                <Terminal />
              </Suspense>
            }
          />
          <Route
            path="printers"
            element={
              <Suspense fallback={<Loader />}>
                <Printers />
              </Suspense>
            }
          />
          <Route
            path="printers/:id"
            element={
              <Suspense fallback={<Loader />}>
                <Printer />
              </Suspense>
            }
          />
          <Route
            path="additional"
            element={
              <Suspense fallback={<Loader />}>
                <EquipmentAdditional />
              </Suspense>
            }
          />
          <Route
            path="additional/:id"
            element={
              <Suspense fallback={<Loader />}>
                <AdditionCard />
              </Suspense>
            }
          />
        </Route> */}
        {/* 
        <Route
          path="request-call"
          element={
            <Suspense fallback={<Loader />}>
              <RequestCall />
            </Suspense>
          }
        /> */}

        {/* <Route
          path="technical-request"
          element={
            <Suspense fallback={<Loader />}>
              <TechnicalRequest />
            </Suspense>
          }
        /> */}

        {/* <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              <NotFoundPage />
            </Suspense>
          }
        /> */}
      </Route>
    </Routes>
  );
};
