/** ----- Modules ----- */
import React, { useState } from "react";
/** ----- Redux ----- */
import { useAppSelector } from "../../redux/store";
/** ----- Types ----- */
import { EProducts } from "../../types/pages";
/** ----- Public ----- */
import qmsImage from "../../assets/home/products/qms.png";
import surveyImage from "../../assets/home/products/survey.png";
import yardImage from "../../assets/home/products/yard.png";
/** ----- Style ----- */
import "../../styles/components/home/products.sass";
import { Button } from "antd";
import { Link } from "react-router-dom";

/** ----- Code ------ */
export const Products = () => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );
  const [images] = useState({
    qms: qmsImage,
    survey: surveyImage,
    "warehouse-yard-management": yardImage,
  });

  const { products } = data[language].components.home;

  return (
    <div className="products">
      <div className="products-inner">
        <div className="products-title">
          <span>{products.title}</span>
        </div>
        <div className="products-container">
          <div className="products-group">
            {products.cards.map(
              (elem: {
                key: EProducts;
                title: string;
                description: string;
                disabled: boolean;
              }) => {
                return (
                  <div className="product-item">
                    <div className="product-item-image">
                      <img src={images[elem.key]} alt="" />
                    </div>
                    <div className="product-item-content">
                      <div className="product-item-content-main">
                        <div className="product-item-title">
                          <span>{elem.title}</span>
                        </div>
                        <div className="product-item-description">
                          <span>{elem.description}</span>
                        </div>
                      </div>
                      {elem.disabled ? (
                        <Button disabled={elem.disabled}>
                          {products["link-disabled"]}
                        </Button>
                      ) : (
                        <Link to={`?page=products&tab=${elem.key}`}>
                          <Button>{products.link}</Button>
                        </Link>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
