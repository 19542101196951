/** ----- Modules ----- */
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
/** ----- Components ----- */
import { Loader } from "../../components/loader";
import { Request } from "../../components/request";
import { ERequest } from "../../utils/request";
import { NotFound } from "../../components/not-found";

/** ----- Code ----- */
export const RequestPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [showCall, setShowCall] = useState(false);
  const [showTechCall, setShowTechCall] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);

  useEffect(() => {
    const page = searchParams.get("tab");
    resetShow();

    if (!page) {
      searchParams.set("tab", "request-call");
      setSearchParams(searchParams);
    } else {
      switch (page) {
        case "request-call":
          setShowCall(true);
          break;
        case "technical-request":
          setShowTechCall(true);
          break;
        default:
          setShowNotFound(true);
          break;
      }
    }
  }, [searchParams]);

  const resetShow = () => {
    setShowCall(false);
    setShowTechCall(false);
    setShowNotFound(false);
  };

  if (showNotFound) return <NotFound />;

  if (showCall) return <Request type={ERequest.call} />;

  if (showTechCall) return <Request type={ERequest.technical} />;

  return <Loader />;
};
