export enum ERequest {
  call,
  technical,
}

export class RequestData {
  clientFullName: string;
  phone: string;
  email: string;
  contract: string;
  country: string;
  city: string;
  emailTitle: string;
  emailBody: string;
  constructor() {
    this.clientFullName = "";
    this.phone = "";
    this.email = "";
    this.contract = "";
    this.country = "Россия";
    this.city = "";
    this.emailTitle = "";
    this.emailBody = "";
  }
}
