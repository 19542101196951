/** ----- Modules ----- */
import React from "react";
/** ----- Style ----- */
import "../../styles/components/products/industries.sass";

/** ----- Code ----- */
export const Industries = ({ language }: { language: any }) => {
  return (
    <div className="industries">
      <div className="industries-inner">
        <div className="industries-title">
          <span>{language.label}</span>
        </div>
        <div className="industries-content">
          {language.list.map(
            (elem: {
              icon: string;
              key: string;
              label: string;
              description: string;
            }) => {
              return (
                <div className="industries-item">
                  <div className="industries-item-inner">
                    <div className="industries-item-label">
                      <span>{elem.label}</span>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};
