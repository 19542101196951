/** ----- Modules ----- */
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
/** ----- Components ----- */
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Upload,
  UploadFile,
  message,
} from "antd";
import { UploadOutlined, SwapRightOutlined } from "@ant-design/icons";
/** ----- Types ----- */
import { ERequest, RequestData } from "../utils/request";
/** ----- Utils ----- */
import { regExp } from "../utils/regexp";
/** ----- Redux ----- */
import { useAppSelector } from "../redux/store";
/** ----- Style ----- */
import "../styles/components/request.sass";
import { Loader } from "./loader";
import { RcFile } from "antd/es/upload";

/** ----- Code ----- */

export const Request = ({ type }: { type: ERequest }) => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );

  const [fileList, setFileList] = useState<any[]>([]);
  const [openConsent, setOpenConsent] = useState<boolean>(false);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const [checkedConsent, setCheckedConsent] = useState<boolean>(false);
  const [formData, setFormData] = useState<RequestData>(new RequestData());

  useEffect(() => {
    setDisabledSubmit(() => {
      if (!checkedConsent) return false;

      if (
        !formData.clientFullName.length ||
        !formData.phone.length ||
        !formData.email.length ||
        !formData.emailTitle.length ||
        !formData.emailBody.length ||
        !formData.country.length ||
        !formData.city.length
      ) {
        return false;
      }

      if (formData.phone !== formData.phone.trim()) {
        return false;
      }

      if (
        !regExp.phone.test(formData.phone) ||
        !regExp.email.test(formData.email) ||
        isNaN(+formData.contract)
      ) {
        return false;
      }

      return true;
    });
  }, [formData, checkedConsent]);

  const uploadDocument = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("document", file);
    try {
      const res = await axios.post(
        "https://api.telegram.org/bot7140284495:AAHynUlVq5XUCBVFa1ELS5PB2xAh4X-L6rA/sendDocument?chat_id=-4194822280",
        fmData,
        config
      );

      onSuccess("Ok");
    } catch (err) {
      onError({ err });
    }
  };

  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file: RcFile) => {
        const allowedFileTypes = [
          "text/plain",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          " application/vnd.oasis.opendocument.text",
          "image/png",
          "image/jpeg",
        ];
        const isType = allowedFileTypes.includes(file.type);
        if (!isType) {
          message.error("Error. Type file.");
        }
        const is10M = file.size / 1024 / 1024 < 10;
        if (!is10M) {
          message.error("Error. File size.");
        }
        if (is10M && isType) {
          setFileList([file]);
          return true;
        } else {
          setFileList([]);
          return false;
        }
      },
      onRemove: (file: UploadFile) => {
        if (fileList.some((item) => item.uid === file.uid)) {
          setFileList((fileList) =>
            fileList.filter((item) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
      onChange(info: any) {
        console.log(info, "CHANGE_INFO");
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          message.success(`${info.file.name}`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
          setFileList([]);
        }
      },
      progress: {
        strokeColor: {
          "0%": "#108ee9",
          "100%": "#87d068",
        },
        strokeWidth: 3,
        format: (percent: any) =>
          percent && `${parseFloat(percent.toFixed(2))}%`,
      },
    }),
    [fileList]
  );

  const onSubmit = async () => {
    const messageReq = {
      "От кого": formData.clientFullName,
      Телефон: formData.phone,
      Почта: formData.email,
      Договор: formData.contract,
      Страна: formData.country,
      Город: formData.city,
      "Тема письма": formData.emailTitle,
      Письмо: formData.emailBody,
    };

    try {
      await axios.post(
        "https://api.telegram.org/bot7140284495:AAHynUlVq5XUCBVFa1ELS5PB2xAh4X-L6rA/sendMessage",
        {
          chat_id: -4194822280,
          text: JSON.stringify(messageReq, null, 2),
        }
      );
    } catch (error) {
      console.error(error);
    }

    setCheckedConsent(false);
    setFormData(new RequestData());
    setFileList([]);
  };

  const onChangeFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, clientFullName: e.target.value }));
  };
  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, phone: e.target.value }));
  };
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, email: e.target.value }));
  };
  const onChangeContract = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, contract: e.target.value }));
  };
  const onChangeCountry = (value: string) => {
    setFormData((prev) => ({ ...prev, country: value }));
  };
  const onChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, city: e.target.value }));
  };
  const onChangeEmailTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, emailTitle: e.target.value }));
  };
  const onChangeEmailBody = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData((prev) => ({ ...prev, emailBody: e.target.value }));
  };

  const { call, technical } = data[language].components.request;

  return (
    <div className="request">
      <div className="request-inner">
        <div className="request-title">
          <span>{type === ERequest.call ? call.title : technical.title}</span>
        </div>
        <div className="request-form">
          <Form
            layout={"vertical"}
            fields={[
              {
                name: call.form.clientFullName.name,
                value: formData.clientFullName,
              },
              {
                name: call.form.phone.name,
                value: formData.phone,
              },
              {
                name: call.form.email.name,
                value: formData.email,
              },
              {
                name: technical.form.contract.name,
                value: formData.contract,
              },
              {
                name: call.form.country.name,
                value: formData.country,
              },
              {
                name: call.form.city.name,
                value: formData.city,
              },
              {
                name: call.form.emailTitle.name,
                value: formData.emailTitle,
              },
              {
                name: call.form.emailBody.name,
                value: formData.emailBody,
              },
            ]}
          >
            <Row>
              <Form.Item
                name={call.form.clientFullName.name}
                label={call.form.clientFullName.label}
                rules={[
                  {
                    required: true,
                    message: call.form.clientFullName.error.required,
                  },
                ]}
              >
                <Input
                  maxLength={120}
                  placeholder={call.form.clientFullName.placeholder}
                  onChange={onChangeFullName}
                />
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                name={call.form.phone.name}
                label={call.form.phone.label}
                rules={[
                  {
                    required: true,
                    message: call.form.phone.error.required,
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }

                      if (value !== value.trim()) {
                        return Promise.reject(call.form.phone.error.trimSpace);
                      }

                      if (!regExp.phone.test(value)) {
                        return Promise.reject(
                          call.form.phone.error.nonCorrectPhone
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  maxLength={100}
                  placeholder={call.form.phone.placeholder}
                  onChange={onChangePhone}
                />
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                name={call.form.email.name}
                label={call.form.email.label}
                rules={[
                  {
                    type: "email",
                    message: call.form.email.error.type,
                  },
                  {
                    required: true,
                    message: call.form.email.error.required,
                  },
                ]}
              >
                <Input
                  maxLength={100}
                  placeholder={call.form.email.placeholder}
                  onChange={onChangeEmail}
                />
              </Form.Item>
            </Row>

            {type === ERequest.technical ? (
              <Row>
                <Form.Item
                  name={technical.form.contract.name}
                  label={technical.form.contract.label}
                  rules={[
                    {
                      required: true,
                      message: technical.form.contract.error.required,
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }

                        if (isNaN(value)) {
                          return Promise.reject(
                            technical.form.contract.error.isNaN
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    maxLength={8}
                    minLength={8}
                    placeholder={technical.form.contract.placeholder}
                    onChange={onChangeContract}
                  />
                </Form.Item>
              </Row>
            ) : null}

            <Row>
              <Form.Item
                name={call.form.country.name}
                label={call.form.country.label}
              >
                <Select
                  options={call.form.country.options}
                  placeholder={call.form.country.placeholder}
                  onChange={onChangeCountry}
                />
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                name={call.form.city.name}
                label={call.form.city.label}
                rules={[
                  {
                    required: true,
                    message: call.form.city.error.required,
                  },
                ]}
              >
                <Input
                  maxLength={120}
                  placeholder={call.form.city.placeholder}
                  onChange={onChangeCity}
                />
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                name={call.form.emailTitle.name}
                label={call.form.emailTitle.label}
                rules={[
                  {
                    required: true,
                    message: call.form.emailTitle.error.required,
                  },
                ]}
              >
                <Input
                  maxLength={60}
                  placeholder={call.form.emailTitle.placeholder}
                  onChange={onChangeEmailTitle}
                />
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                name={call.form.emailBody.name}
                label={call.form.emailBody.label}
                rules={[
                  {
                    required: true,
                    message: call.form.emailBody.error.required,
                  },
                ]}
              >
                <Input.TextArea
                  rows={6}
                  maxLength={5000}
                  placeholder={call.form.emailBody.placeholder}
                  onChange={onChangeEmailBody}
                />
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                name={call.form.uploadFile.name}
                label={call.form.uploadFile.label}
              >
                <Upload
                  multiple={false}
                  customRequest={uploadDocument}
                  {...uploadProps}
                  defaultFileList={fileList}
                  showUploadList={Boolean(fileList.length)}
                >
                  {fileList.length ? null : (
                    <Button icon={<UploadOutlined />}>
                      {call.form.uploadFile.button}
                    </Button>
                  )}
                </Upload>
              </Form.Item>
            </Row>
          </Form>

          <div className="request-consent">
            <Checkbox
              checked={checkedConsent}
              onChange={() => {
                setOpenConsent(true);
                setCheckedConsent(false);
              }}
            >
              {call.consent.label}
            </Checkbox>
          </div>
          <div className="request-submit">
            <Button
              icon={<SwapRightOutlined />}
              disabled={!disabledSubmit}
              onClick={onSubmit}
              className={"request-submit-button"}
            >
              {call.submit.button}
            </Button>
          </div>
        </div>
      </div>

      {/* Modal: consent */}
      <Modal
        title={call.consent.modal.title}
        closeIcon={false}
        open={openConsent}
        maskClosable={false}
        footer={[
          null,
          <Space>
            <Button
              onClick={() => {
                setOpenConsent(false);
                setCheckedConsent(true);
              }}
            >
              {call.consent.modal.body.button.confirm}
            </Button>
            <Button
              onClick={() => {
                setOpenConsent(false);
                setCheckedConsent(false);
              }}
            >
              {call.consent.modal.body.button.cancel}
            </Button>
          </Space>,
        ]}
      >
        <div className="request-modal-consent">
          {call.consent.modal.body.paragraph.map((elem: string) => {
            return <p className="consent-paragraph">{elem}</p>;
          })}
        </div>
      </Modal>
    </div>
  );
};
