export enum ILanguagePick {
  ru = "ru",
  en = "en",
  az = "az",
  hy = "hy",
  kk = "kk",
  uz = "uz",
}

export const languageCookie = "lga-solutions-language";
