/** ----- Modules ----- */
import React from "react";
import { Link } from "react-router-dom";
/** ----- Redux ----- */
import { useAppSelector } from "../redux/store";
/** ----- Styles ----- */
import "../styles/components/not-found.sass";

/** ----- Code ------ */
export const NotFound = () => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );
  const { notFound: notFoundLang } = data[language].components;

  return (
    <div className="not-found">
      <div className="not-found-inner">
        <span className="not-found-code">{notFoundLang.code}</span>
        <span className="not-found-text">
          {notFoundLang.text}
          <Link to={"/"}>{notFoundLang.home}</Link>
        </span>
      </div>
    </div>
  );
};
