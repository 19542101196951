/** ----- Modules ----- */
import React from "react";
import { useSearchParams } from "react-router-dom";
/** ----- Components ----- */
import { NotFound } from "../../components/not-found";
import { SubmitApp } from "../../components/home/submit";
/** ----- Redux ----- */
import { useAppSelector } from "../../redux/store";
/** ----- Public ----- */
import xp365b from "../../assets/equipment/xp365b.png";
/** ----- Styles ----- */
import "../../styles/pages/equipment-card.sass";

/** ----- Code ----- */
const printersImages: { [key: string]: string } = {
  xp365b: xp365b,
};
const Printer = () => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const printerKey = searchParams.get("printer");

  const { printers } = data[language].components.equipment;

  const itemPrinter = printers.list.find(
    (elem: {
      key: string;
      name: string;
      description: string[];
      specifications: { label: string; list: { label: string; value: string } };
    }) => elem.key === printerKey
  );

  if (!itemPrinter) return <NotFound />;

  return (
    <>
      <div className="equipment-card-preview">
        <div className="equipment-card-preview-inner">
          <div className="equipment-card-preview-image">
            {printerKey ? (
              <img src={printersImages[printerKey]} alt={printerKey} />
            ) : null}
          </div>
          <div className="equipment-card-preview-container">
            <div className="equipment-card-preview-container-title">
              <span>{itemPrinter.name}</span>
            </div>
            <div className="equipment-card-preview-container-description">
              {itemPrinter.description.map((prg: string) => {
                return <p className="description-paragraph">{prg}</p>;
              })}
            </div>
          </div>
        </div>
      </div>
      <SubmitApp />
      <div className="specifications">
        <div className="specifications-inner">
          <div className="spec-title">
            <span>{itemPrinter.specifications.label}</span>
          </div>
          <div className="spec-data">
            {itemPrinter.specifications.list.map(
              (elem: { label: string; value: string }) => {
                return (
                  <div className="spec-data-item">
                    <div className="spec-label">{elem.label}</div>
                    <div className="spec-line"></div>
                    <div className="spec-value">{elem.value}</div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Printer;
