/** ----- Modules ----- */
import axios from "axios";
import Cookies from "js-cookie";
/** ----- Redux ----- */
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IStatus } from "../../types/status";
import { ILanguagePick, languageCookie } from "../../types/language";

/** ----- Code ----- */
export const fetchLanguagePack = createAsyncThunk(
  "languagePack/fetchLanguagePack",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<string>("./lang.json");
      return await response;
    } catch (error) {
      return thunkAPI.rejectWithValue({});
    }
  }
);

interface ILanguagePackSlice {
  data: any;
  selected: ILanguagePick;
  status: IStatus;
}

const initialState: ILanguagePackSlice = {
  data: "",
  selected: ILanguagePick.ru,
  status: IStatus.idle,
};

const languagePack = createSlice({
  name: "languagePack",
  initialState,
  reducers: {
    readCookieLanguage: (state) => {
      const selectedLanguage = Cookies.get(languageCookie);

      if (selectedLanguage) {
        state.selected = selectedLanguage as ILanguagePick;
      }
    },
    changeLanguage: (state, action: PayloadAction<ILanguagePick>) => {
      const { payload } = action;
      state.selected = payload;
      Cookies.set(languageCookie, payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLanguagePack.pending, (state) => {
      state.data = "";
      state.status = IStatus.loading;
    });
    builder.addCase(fetchLanguagePack.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.status = IStatus.success;
    });
    builder.addCase(fetchLanguagePack.rejected, (state) => {
      state.data = "";
      state.status = IStatus.error;
    });
  },
});

export default languagePack.reducer;
export const { readCookieLanguage, changeLanguage } = languagePack.actions;
