/** ----- Modules ----- */
import React from "react";
/** ----- Components ----- */
import { Link } from "react-router-dom";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
/** ----- Style ----- */
import "../../styles/components/products/preview.sass";

/** ----- Code ----- */
export const ProductPreview = ({
  image,
  language,
  requestCall,
}: {
  image: any;
  language: any;
  requestCall: boolean;
}) => {
  return (
    <div className="preview">
      <div className="preview-inner">
        <div className="preview-image">
          <img src={image} alt="about" />
        </div>
        <div className="preview-container">
          <div className="preview-container-title">
            <span>{language.label}</span>
          </div>
          <div className="preview-container-description">
            {language.description.map((prg: string) => {
              return <p className="description-paragraph">{prg}</p>;
            })}
          </div>
          {requestCall ? (
            <div className="preview-container-request">
              <Link to={"?page=request&tab=request-call"}>
                <Button icon={<ArrowRightOutlined />}>
                  {language.request}
                </Button>
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
