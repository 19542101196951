/** ----- Modules ----- */
import React from "react";
/** ----- Components ----- */
import {
  DesktopOutlined,
  AppstoreAddOutlined,
  SettingOutlined,
  GlobalOutlined,
  LockOutlined,
  FundProjectionScreenOutlined,
  CloudOutlined,
  RadarChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
/** ----- Style ----- */
import "../../styles/components/products/advantages.sass";

/** ----- Code ----- */
const iconsAdvantages: { [key: string]: JSX.Element } = {
  DesktopOutlined: <DesktopOutlined />,
  AppstoreAddOutlined: <AppstoreAddOutlined />,
  SettingOutlined: <SettingOutlined />,
  GlobalOutlined: <GlobalOutlined />,
  LockOutlined: <LockOutlined />,
  FundProjectionScreenOutlined: <FundProjectionScreenOutlined />,
  CloudOutlined: <CloudOutlined />,
  RadarChartOutlined: <RadarChartOutlined />,
  UserOutlined: <UserOutlined />,
};
export const Advantages = ({ language }: { language: any }) => {
  return (
    <div className="advantages">
      <div className="advantages-inner">
        <div className="advantages-title">
          <span>{language.label}</span>
        </div>
        <div className="advantages-content">
          {language.list.map(
            (elem: {
              icon: string;
              key: string;
              label: string;
              description: string;
            }) => {
              return (
                <div className="advantages-item">
                  <div className="advantages-item-inner">
                    <div className="advantages-item-icon">
                      {iconsAdvantages[elem.icon]}
                    </div>
                    <div className="advantages-item-label">
                      <span>{elem.label}</span>
                    </div>
                    <div className="advantages-item-description">
                      <p className="description-text">{elem.description}</p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};
