/** ----- Modules ----- */
import React, { useEffect, useState } from "react";
/** ----- Components ----- */
import PrinterCard from "./printer-card";
import { Link, useLocation, useSearchParams } from "react-router-dom";
/** ----- Redux ----- */
import { useAppSelector } from "../../redux/store";
/** ----- Public ----- */
import xp365b from "../../assets/equipment/xp365b.png";
/** ----- Styles ----- */
import "../../styles/pages/equipments.sass";

/** ----- Code ----- */
const printersImages: { [key: string]: string } = {
  xp365b: xp365b,
};

export const Printers = () => {
  const location = useLocation();
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [showPrinter, setShowPrinter] = useState(false);

  useEffect(() => {
    const page = searchParams.get("printer");

    setShowPrinter(false);

    if (page) {
      setShowPrinter(true);
    }
  }, [searchParams]);

  const { printers } = data[language].components.equipment;

  if (showPrinter) return <PrinterCard />;

  return (
    <div className="equipments">
      <div className="equipments-inner">
        <div className="equipments-title">
          <span>{printers.label}</span>
        </div>
        <div className="equipments-cards">
          {printers.list.map(
            (elem: {
              key: string;
              name: string;
              image: string;
              description: string[];
              specifications: {
                label: string;
                list: { label: string; value: string }[];
              };
            }) => {
              return (
                <div className="equipment-item">
                  <Link to={`${location.search}&printer=${elem.key}`}>
                    <div className="equipment-item-inner">
                      <div className="equipment-image">
                        <img src={printersImages[elem.key]} alt="" />
                      </div>
                      <div className="equipment-name">
                        <span>{elem.name}</span>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};
