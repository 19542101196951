/** ----- Modules ----- */
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
/** ----- Components ----- */
import { Terminals } from "./terminals";
import { Printers } from "./printers";
import { Additional } from "./additional";
import { Loader } from "../../components/loader";
import { NotFound } from "../../components/not-found";

/** ----- Code ----- */
export const EquipmentPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [showNotFound, setShowNotFound] = useState(false);
  const [showTerminals, setShowTerminals] = useState(false);
  const [showPrinters, setShowPrinters] = useState(false);
  const [showAdditional, setShowAdditional] = useState(false);

  useEffect(() => {
    const page = searchParams.get("tab");
    resetShow();

    if (!page) {
      searchParams.set("tab", "qms");
      setSearchParams(searchParams);
    } else {
      switch (page) {
        case "terminals":
          setShowTerminals(true);
          break;
        case "printers":
          setShowPrinters(true);
          break;
        case "additional":
          setShowAdditional(true);
          break;
        default:
          setShowNotFound(true);
          break;
      }
    }
  }, [searchParams]);

  const resetShow = () => {
    setShowTerminals(false);
    setShowPrinters(false);
    setShowAdditional(false);
    setShowNotFound(false);
  };

  if (showNotFound) return <NotFound />;

  if (showTerminals) return <Terminals />;
  if (showPrinters) return <Printers />;
  if (showAdditional) return <Additional />;

  return <Loader />;
};
