/** ----- Modules ----- */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
/** ----- Components ------ */
import { Button, Drawer, Dropdown, Menu, Select, Space } from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
/** ----- Types ----- */
import { EEquipment, EProducts, ERequest } from "../types/pages";
import { IDropdown } from "../types/antd";
import { ILanguagePick } from "../redux/types/language";
/** ----- Redux ----- */
import { changeLanguage } from "../redux/slices/language";
import { useAppDispatch, useAppSelector } from "../redux/store";
/** ----- Assets ----- */
import CompanyLogo from "../assets/header/company-logo.svg";
/** ----- Styles ----- */
import "../styles/components/header.sass";

/** ----- Code ----- */
export const Header = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );
  const [optionsLang, setOptionsLang] = useState<
    { label: string; value: string }[]
  >([]);
  const [products, setProducts] = useState<IDropdown[]>([]);
  const [equipment, setEquipment] = useState<IDropdown[]>([]);
  const [request, setRequest] = useState<IDropdown[]>([]);

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    setIsSmallScreen(window.innerWidth < 569);

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 569);
      setOpenMenu(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (data) {
      const {
        products: productsList,
        equipment: equipmentList,
        request: requestList,
      } = data[language].components.header.navigation;
      setOptionsLang(() =>
        Object.entries(data).map((elem: [string, any]) => {
          return {
            value: elem[0],
            label: elem[1].name,
          };
        })
      );

      setProducts(() =>
        productsList.map(
          (elem: { label: string; key: EProducts; disabled: boolean }) => {
            const locationPath = `?page=products&tab=${EProducts[elem.key]}`;
            setKeys((prev) => [...prev, elem.key]);
            return setItems(elem, locationPath);
          }
        )
      );

      setEquipment(() =>
        equipmentList.map(
          (elem: { label: string; key: EEquipment; disabled: boolean }) => {
            const locationPath = `?page=equipment&tab=${EEquipment[elem.key]}`;
            setKeys((prev) => [...prev, elem.key]);
            return setItems(elem, locationPath);
          }
        )
      );

      setRequest(() =>
        requestList.map(
          (elem: { label: string; key: ERequest; disabled: boolean }) => {
            const locationPath = `?page=request&tab=${ERequest[elem.key]}`;
            setKeys((prev) => [...prev, elem.key]);
            return setItems(elem, locationPath);
          }
        )
      );
    }
  }, [language]);

  const onChangeLanguage = (value: string) => {
    dispatch(changeLanguage(value as ILanguagePick));
  };

  const setItems = (
    elem: { label: string; key: string; disabled: boolean },
    locationPath: string
  ) => {
    return {
      key: elem.key,
      disabled: elem.disabled,
      label: elem.disabled ? (
        <div className="link-label">{elem.label}</div>
      ) : (
        <Link to={locationPath}>
          <div className="link-label">{elem.label}</div>
        </Link>
      ),
    };
  };

  const { header: headerLang } = data[language].components;

  const selectItem = pathname.split("/").pop();

  return (
    <header className="header">
      <div className="header-inner">
        <div className="logo">
          <div className="logo-inner">
            <Link className="logo-link" to={"/"}>
              <div className="company-logo">
                <img
                  className="company-logo-img"
                  src={CompanyLogo}
                  alt="company-logo"
                />
              </div>
              <div className="company-name">
                <span className="company-name-label">
                  {headerLang.company.name}
                </span>
              </div>
            </Link>
          </div>
        </div>
        {isSmallScreen ? (
          <Button
            className="header-burger-menu-button"
            icon={<MenuOutlined />}
            onClick={() => setOpenMenu(true)}
          ></Button>
        ) : (
          <>
            <div className="navigation">
              <div className="navigation-item">
                <Dropdown
                  menu={{
                    items: products,
                  }}
                >
                  <Space>
                    {headerLang.navigation.labels.products}
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </div>
              <div className="navigation-item">
                <Dropdown
                  menu={{
                    items: equipment,
                  }}
                >
                  <Space>
                    {headerLang.navigation.labels.equipment}
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </div>
              <div className="navigation-item">
                <Dropdown
                  menu={{
                    items: request,
                  }}
                >
                  <Space>
                    {headerLang.navigation.labels.request}
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </div>
            </div>
            <div className="language">
              {optionsLang.length < 2 ? null : (
                <Select
                  className="language-select"
                  options={optionsLang}
                  onChange={onChangeLanguage}
                  value={language}
                />
              )}
            </div>
          </>
        )}
      </div>

      <Drawer
        open={openMenu}
        title={headerLang.drawer.title}
        maskClosable={false}
        closable={false}
        width={"100%"}
        extra={
          <div className="drawer-extra">
            <Button onClick={() => setOpenMenu(false)}>
              {headerLang.drawer.button.close}
            </Button>
          </div>
        }
      >
        <Menu
          mode={"inline"}
          theme={"light"}
          selectedKeys={[
            selectItem && keys.includes(selectItem) ? selectItem : "",
          ]}
          onSelect={() => setOpenMenu(false)}
          style={{ width: "100%", border: "none" }}
          items={[
            setItems(headerLang.navigation.home, "/"),
            ...products,
            ...equipment,
            ...request,
          ]}
        />
      </Drawer>
    </header>
  );
};
