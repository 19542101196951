/** ----- Modules ----- */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
/** ----- Components ----- */
import {
  FormOutlined,
  UserSwitchOutlined,
  TruckOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
/** ----- Types ----- */
import { EProducts } from "../../types/pages";
/** ----- Redux ----- */
import { useAppSelector } from "../../redux/store";
/** ----- Public ----- */
import bg_1 from "../../assets/home/cover/1.svg";
/** ----- Style ----- */
import "../../styles/components/home/cover.sass";

/** ----- Code ----- */
const coverIcons: { [key: string]: JSX.Element } = {
  qms: <UserSwitchOutlined />,
  survey: <FormOutlined />,
  "warehouse-yard-management": <TruckOutlined />,
};

export const Cover = () => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );

  const { cover } = data[language].components.home;

  return (
    <div className="cover">
      <div className="cover-mask"></div>
      <div className="cover-inner" style={{ backgroundImage: `url(${bg_1})` }}>
        <div className="cover-no-show"></div>
        <div className="cover-main">
          <div className="cover-title">
            <span>{cover.title}</span>
          </div>
          <div className="cover-description">
            <span>{cover.description}</span>
          </div>
          <Link to={"?page=request&tab=request-call"}>
            <Button>{cover.link}</Button>
          </Link>
        </div>
        <div className="cover-addon">
          <div className="cover-products-group">
            {cover.products.map(
              (elem: {
                key: EProducts;
                title: string;
                description: string;
              }) => {
                return (
                  <div className="cover-product-item">
                    <div className="cover-product-item-title">
                      <div className="item-title-icon">
                        {coverIcons[elem.key]}
                      </div>
                      <div className="item-title-text">
                        <span>{elem.title}</span>
                      </div>
                    </div>
                    <div className="cover-product-item-description">
                      <span>{elem.description}</span>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
