/** ----- Modules ----- */
import React, { useEffect, useState } from "react";
/** ----- Components ----- */
import { Empty } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
/** ----- Styles ----- */
import "../../styles/components/error/language.sass";

/** ----- Code ----- */
export const LanguageError = () => {
  const [timeReload, setTimeReload] = useState<number>(5);

  useEffect(() => {
    const intervalReload = setInterval(() => {
      setTimeReload((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalReload);
  }, []);

  useEffect(() => {
    if (timeReload === 0) {
      window.location.reload();
    }
  }, [timeReload]);

  return (
    <div className="language-error">
      <div className="language-error-inner">
        <Empty
          className="language-error-empty"
          description={`
          Error receiving the language pack.
          The page will automatically reload after ${timeReload} seconds.
        `}
          image={<CloseCircleOutlined />}
        />
      </div>
    </div>
  );
};
