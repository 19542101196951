/** ----- Modules ----- */
import React, { useEffect, useState } from "react";
/** ----- Components ----- */
import AdditionalCard from "./additional-card";
import { Link, useLocation, useSearchParams } from "react-router-dom";
/** ----- Redux ----- */
import { useAppSelector } from "../../redux/store";
/** ----- Public ----- */
import ribbon01 from "../../assets/equipment/ribbon01.jpg";
import tlk32 from "../../assets/equipment/32fkn1.jpg";
import tlk40 from "../../assets/equipment/40fkn1.jpg";
import tlk43 from "../../assets/equipment/43fkn1.jpg";
import minipc01 from "../../assets/equipment/minipc01.jpg";
/** ----- Styles ----- */
import "../../styles/pages/equipments.sass";

/** ----- Code ----- */
const additionalImages: { [key: string]: string } = {
  ribbon01: ribbon01,
  tlk32: tlk32,
  tlk40: tlk40,
  tlk43: tlk43,
  minipc01: minipc01,
};
export const Additional = () => {
  const location = useLocation();
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [showAdditional, setShowAdditional] = useState(false);

  useEffect(() => {
    const page = searchParams.get("additional");

    setShowAdditional(false);

    if (page) {
      setShowAdditional(true);
    }
  }, [searchParams]);

  const { additional } = data[language].components.equipment;
  if (showAdditional) return <AdditionalCard />;

  return (
    <div className="equipments">
      <div className="equipments-inner">
        <div className="equipments-title">
          <span>{additional.label}</span>
        </div>
        <div className="equipments-cards">
          {additional.list.map(
            (elem: {
              key: string;
              name: string;
              image: string;
              description: string[];
              specifications: {
                label: string;
                list: { label: string; value: string }[];
              };
            }) => {
              return (
                <div className="equipment-item">
                  <Link to={`${location.search}&additional=${elem.key}`}>
                    <div className="equipment-item-inner">
                      <div className="equipment-image">
                        <img src={additionalImages[elem.key]} alt="" />
                      </div>
                      <div className="equipment-name">
                        <span>{elem.name}</span>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};
