/** ----- Modules ----- */
import React from "react";
/** ----- Components ----- */
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
/** ----- Assets ----- */
import LoaderLogo from "../assets/loader/loader-logo.svg";
/** ----- Styles ----- */
import "../styles/components/loader.sass";

export const Loader = () => {
  return (
    <div className="loader-mask">
      <div className="loader-mask-inner">
        <Spin
          className="loader-mask-spin"
          indicator={<LoadingOutlined className="loader-mask-spin-icon" />}
        />
        <div className="loader-mask-logo">
          <img
            className="loader-mask-logo-img"
            src={LoaderLogo}
            alt="logo-lga"
          />
        </div>
      </div>
    </div>
  );
};
