/** ----- Modules ----- */
import React from "react";
/** ----- Style ----- */
import "../../styles/components/products/settings.sass";

/** ----- Code ----- */
export const Settings = ({ language }: { language: any }) => {
  return (
    <div className="settings">
      <div className="settings-inner">
        <div className="settings-title">
          <span>{language.label}</span>
        </div>
        <div className="settings-content">
          <div className="content-intro">
            {language.content.intro.map((prg: string) => {
              return <p>{prg}</p>;
            })}
          </div>
          <div className="content-advantages">
            <div className="content-advantages-label">
              <span>{language.content.advantages.label}</span>
            </div>
            <div className="content-advantages-list">
              {language.content.advantages.list.map((prg: string) => {
                return <p>{prg}</p>;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
