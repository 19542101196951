/** ----- Modules ----- */
import React from "react";
/** ----- Components ----- */
import {
  UserOutlined,
  CheckCircleOutlined,
  UnlockOutlined,
  AppstoreAddOutlined,
  SettingOutlined,
  CommentOutlined,
} from "@ant-design/icons";
/** ----- Redux ----- */
import { useAppSelector } from "../../redux/store";
/** ----- Style ----- */
import "../../styles/components/home/why-we.sass";

/** ----- Code ----- */
const iconsWhyWe: { [key: string]: JSX.Element } = {
  UserOutlined: <UserOutlined />,
  CheckCircleOutlined: <CheckCircleOutlined />,
  UnlockOutlined: <UnlockOutlined />,
  AppstoreAddOutlined: <AppstoreAddOutlined />,
  SettingOutlined: <SettingOutlined />,
  CommentOutlined: <CommentOutlined />,
};
export const WhyWe = () => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );

  const { whyWe } = data[language].components.home;
  return (
    <div className="why-we">
      <div className="why-we-inner">
        <div className="why-we-title">
          <span>{whyWe.title}</span>
        </div>
        <div className="why-we-content">
          {whyWe.items.map(
            (elem: { icon: string; label: string; description: string }) => {
              return (
                <div className="why-we-item">
                  <div className="why-we-item-inner">
                    <div className="why-we-item-icon">
                      {iconsWhyWe[elem.icon]}
                    </div>
                    <div className="why-we-item-label">
                      <span>{elem.label}</span>
                    </div>
                    <div className="why-we-item-description">
                      <p className="description-text">{elem.description}</p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};
