/** ----- Modules ----- */
import React from "react";
import { Link, useLocation } from "react-router-dom";
/** ----- Components ----- */
import { PhoneOutlined, MailOutlined, HomeOutlined } from "@ant-design/icons";
/** ----- Redux ----- */
import { useAppSelector } from "../redux/store";
/** ----- Styles ----- */
import "../styles/components/footer.sass";

/** ----- Code ----- */
export const Footer = () => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );
  const location = useLocation();

  const { footer: footerLang } = data[language].components;

  const { navigation: navigationFooter, contact: contactFooter } =
    footerLang.content;

  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="footer-main">
          <div className="footer-contact">
            <div className="contact-departments">
              {contactFooter.departments.map(
                (elem: { label: string; phone: string; email: string }) => {
                  return (
                    <div className="contact-departments-group">
                      <div className="departments-group-label">
                        <span className="department-label">{elem.label}</span>
                      </div>
                      <div className="departments-group-info">
                        {/* <div className="departments-info-phone">
                          <span className="departments-info-phone-value">
                            <a href={`tel:${elem.phone}`}>
                              <PhoneOutlined />
                              {elem.phone}
                            </a>
                          </span>
                        </div> */}
                        <div className="departments-info-email">
                          <span className="departments-info-email-value">
                            <a href={`mailto:${elem.email}`}>
                              <MailOutlined />
                              {elem.email}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <div className="contact-company">
              <div className="company-name-org">
                {contactFooter.company.name}
              </div>
              <div className="company-address-org">
                <span className="company-address-org-value">
                  <HomeOutlined />
                  {contactFooter.company.address}
                </span>
              </div>
              <div className="company-info-email-org">
                <span className="company-info-email-org-value">
                  <a href={`mailto:${contactFooter.company.email}`}>
                    <MailOutlined />
                    {contactFooter.company.email}
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="footer-navigation">
            <div className="footer-navigation-item">
              <div className="footer-navigation-item-group">
                <div className="footer-navigation-item-group-label footer-navigation-item-group-label-home">
                  <span className="nav-item-home">
                    <span
                      className={`dot ${
                        location.pathname === "/" ? "selected-link" : ""
                      }`}
                    ></span>
                    <Link to={"/"}>{navigationFooter.home.label}</Link>
                  </span>
                </div>
              </div>
              <div className="footer-navigation-item-group">
                <div className="footer-navigation-item-group-label">
                  <span className="group-label">
                    {navigationFooter.products.label}
                  </span>
                </div>
                <div className="footer-navigation-item-group-values">
                  {navigationFooter.products.links.map(
                    (elem: {
                      label: string;
                      key: string;
                      disabled: boolean;
                    }) => {
                      return (
                        <span className="nav-item">
                          <span
                            className={`dot ${
                              location.pathname === "/" + elem.key
                                ? "selected-link"
                                : ""
                            }`}
                          ></span>
                          {elem.disabled ? (
                            <a
                              href="/"
                              onClick={(e) => e.preventDefault()}
                              className="link-disabled"
                            >
                              {elem.label}
                            </a>
                          ) : (
                            <Link
                              to={`?page=${navigationFooter.products.key}&tab=${elem.key}`}
                            >
                              {elem.label}
                            </Link>
                          )}
                        </span>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="footer-navigation-item">
              <div className="footer-navigation-item-group">
                <div className="footer-navigation-item-group-label">
                  <span className="group-label">
                    {navigationFooter.equipment.label}
                  </span>
                </div>
                <div className="footer-navigation-item-group-values">
                  {navigationFooter.equipment.links.map(
                    (elem: {
                      label: string;
                      key: string;
                      disabled: boolean;
                    }) => {
                      return (
                        <span className="nav-item">
                          <span
                            className={`dot ${
                              location.pathname === "/" + elem.key
                                ? "selected-link"
                                : ""
                            }`}
                          ></span>
                          {elem.disabled ? (
                            <a
                              href="/"
                              onClick={(e) => e.preventDefault()}
                              className="link-disabled"
                            >
                              {elem.label}
                            </a>
                          ) : (
                            <Link
                              to={`?page=${navigationFooter.equipment.key}&tab=${elem.key}`}
                            >
                              {elem.label}
                            </Link>
                          )}
                        </span>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="footer-navigation-item">
              <div className="footer-navigation-item-group">
                <div className="footer-navigation-item-group-label">
                  <span className="group-label">
                    {navigationFooter.request.label}
                  </span>
                </div>
                <div className="footer-navigation-item-group-values">
                  {navigationFooter.request.links.map(
                    (elem: {
                      label: string;
                      key: string;
                      disabled: boolean;
                    }) => {
                      return (
                        <span className="nav-item">
                          <span
                            className={`dot ${
                              location.pathname === "/" + elem.key
                                ? "selected-link"
                                : ""
                            }`}
                          ></span>
                          {elem.disabled ? (
                            <a
                              href="/"
                              onClick={(e) => e.preventDefault()}
                              className="link-disabled"
                            >
                              {elem.label}
                            </a>
                          ) : (
                            <Link to={`?page=request&tab=${elem.key}`}>
                              {elem.label}
                            </Link>
                          )}
                        </span>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-post">
          <span className="footer-post-label">
            {`© ${footerLang.post.year} ${footerLang.post.reserved} ${footerLang.post.company.name}`}
          </span>
        </div>
      </div>
    </footer>
  );
};
