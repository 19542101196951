/** ----- Modules ----- */
import React from "react";
/** ----- Components ----- */
import { Button } from "antd";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
/** ----- Redux ----- */
import { useAppSelector } from "../../redux/store";
/** ----- Style ----- */
import "../../styles/components/home/submit.sass";

/** ----- Code ----- */
export const SubmitApp = () => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );

  const { submit } = data[language].components.home;

  return (
    <div className="submit-app">
      <div className="submit-app-inner">
        <div className="submit-app-main">
          <div className="submit-app-main-title">
            <span>{submit.title}</span>
          </div>
          <div className="submit-app-main-description">
            <span>{submit.description}</span>
          </div>
        </div>
        <div className="submit-app-request">
          <Link to={"?page=request&tab=request-call"}>
            <Button icon={<ArrowRightOutlined />}>{submit.link}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
