/** ----- Modules ----- */
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
/** ----- Components ----- */
import { Loader } from "../../components/loader";
import { NotFound } from "../../components/not-found";
import { QMS } from "./qms";

/** ----- Code ----- */
export const ProductsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [showQMS, setShowQMS] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);

  useEffect(() => {
    const page = searchParams.get("tab");
    resetShow();

    if (!page) {
      searchParams.set("tab", "qms");
      setSearchParams(searchParams);
    } else {
      switch (page) {
        case "qms":
          setShowQMS(true);
          break;
        default:
          setShowNotFound(true);
          break;
      }
    }
  }, [searchParams]);

  const resetShow = () => {
    setShowQMS(false);
    setShowNotFound(false);
  };

  if (showNotFound) return <NotFound />;

  if (showQMS) return <QMS />;

  return <Loader />;
};
