/** ----- Modules ----- */
import React from "react";
import { useSearchParams } from "react-router-dom";
/** ----- Components ----- */
import { NotFound } from "../../components/not-found";
import { SubmitApp } from "../../components/home/submit";
/** ----- Redux ----- */
import { useAppSelector } from "../../redux/store";
/** ----- Public ----- */
import ribbon01 from "../../assets/equipment/ribbon01.jpg";
import tlk32 from "../../assets/equipment/32fkn1.jpg";
import tlk40 from "../../assets/equipment/40fkn1.jpg";
import tlk43 from "../../assets/equipment/43fkn1.jpg";
import minipc01 from "../../assets/equipment/minipc01.jpg";
/** ----- Styles ----- */
import "../../styles/pages/equipment-card.sass";

/** ----- Code ----- */
const additionalImages: { [key: string]: string } = {
  ribbon01: ribbon01,
  tlk32: tlk32,
  tlk40: tlk40,
  tlk43: tlk43,
  minipc01: minipc01,
};
const AdditionCard = () => {
  const { data, selected: language } = useAppSelector(
    (state) => state.languagePackReducer
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const additionKey = searchParams.get("additional");

  const { additional } = data[language].components.equipment;

  const itemAddition = additional.list.find(
    (elem: {
      key: string;
      name: string;
      description: string[];
      specifications: { label: string; list: { label: string; value: string } };
    }) => elem.key === additionKey
  );

  if (!itemAddition) return <NotFound />;

  return (
    <>
      <div className="equipment-card-preview">
        <div className="equipment-card-preview-inner">
          <div className="equipment-card-preview-image">
            {additionKey ? (
              <img src={additionalImages[additionKey]} alt={additionKey} />
            ) : null}
          </div>
          <div className="equipment-card-preview-container">
            <div className="equipment-card-preview-container-title">
              <span>{itemAddition.name}</span>
            </div>
            <div className="equipment-card-preview-container-description">
              {itemAddition.description.map((prg: string) => {
                return <p className="description-paragraph">{prg}</p>;
              })}
            </div>
          </div>
        </div>
      </div>
      <SubmitApp />
      <div className="specifications">
        <div className="specifications-inner">
          <div className="spec-title">
            <span>{itemAddition.specifications.label}</span>
          </div>
          <div className="spec-data">
            {itemAddition.specifications.list.map(
              (elem: { label: string; value: string }) => {
                return (
                  <div className="spec-data-item">
                    <div className="spec-label">{elem.label}</div>
                    <div className="spec-line"></div>
                    <div className="spec-value">{elem.value}</div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdditionCard;
