/** ----- Modules ----- */
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
/** ----- Components ----- */
import { Cover } from "../components/home/cover";
import { About } from "../components/home/about";
import { WhyWe } from "../components/home/why-we";
import { Products } from "../components/home/products";
import { SubmitApp } from "../components/home/submit";

import { Loader } from "../components/loader";
import { NotFound } from "../components/not-found";
import { ProductsPage } from "./products";
import { EquipmentPage } from "./equipment";
import { RequestPage } from "./request";

/** ----- Code ----- */
export const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [showLoader, setShowLoader] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);

  const [showProduct, setShowProduct] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [showEquipment, setShowEquipment] = useState(false);

  useEffect(() => {
    const page = searchParams.get("page");
    resetShow();

    if (!page) {
      searchParams.set("page", "home");
      setSearchParams(searchParams);
    } else {
      switch (page) {
        case "home":
          showComponent();
          break;
        case "products":
          showComponent(() => setShowProduct(true));
          break;
        case "equipment":
          showComponent(() => setShowEquipment(true));
          break;
        case "request":
          showComponent(() => setShowRequest(true));
          break;
        default:
          showComponent(() => setShowNotFound(true));
          break;
      }
    }
  }, [searchParams]);

  const resetShow = () => {
    setShowProduct(false);
    setShowRequest(false);
    setShowEquipment(false);
    setShowNotFound(false);
    setShowLoader(true);
  };

  const showComponent = (callback?: Function) => {
    callback && callback();

    setShowLoader(false);
  };

  if (showLoader) return <Loader />;

  if (showNotFound) return <NotFound />;

  if (showProduct) return <ProductsPage />;

  if (showEquipment) return <EquipmentPage />;

  if (showRequest) return <RequestPage />;

  return (
    <>
      <Cover />
      <About />
      <SubmitApp />
      <WhyWe />
      <Products />
    </>
  );
};
