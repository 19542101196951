export enum EProducts {
  "qms" = "qms",
  "survey" = "survey",
  "warehouse-yard-management" = "warehouse-yard-management",
}

export enum EEquipment {
  "terminals" = "terminals",
  "information-kiosks" = "information-kiosks",
  "information-boards" = "information-boards",
  "printers" = "printers",
  "additional" = "additional",
}

export enum ERequest {
  "request-call" = "request-call",
  "technical-request" = "technical-request",
}
