/** ----- Modules ----- */
import React from "react";
/** ----- Components ----- */
import {
  FieldTimeOutlined,
  ScheduleOutlined,
  LineChartOutlined,
  SmileOutlined,
} from "@ant-design/icons";
/** ----- Style ----- */
import "../../styles/components/products/tasks.sass";

/** ----- Code ----- */
const iconsTasks: { [key: string]: JSX.Element } = {
  FieldTimeOutlined: <FieldTimeOutlined />,
  ScheduleOutlined: <ScheduleOutlined />,
  LineChartOutlined: <LineChartOutlined />,
  SmileOutlined: <SmileOutlined />,
};
export const Tasks = ({ language }: { language: any }) => {
  return (
    <div className="tasks">
      <div className="tasks-inner">
        <div className="tasks-title">
          <span>{language.label}</span>
        </div>
        <div className="tasks-content">
          {language.list.map(
            (elem: {
              icon: string;
              key: string;
              label: string;
              description: string;
            }) => {
              return (
                <div className="tasks-item">
                  <div className="tasks-item-inner">
                    <div className="tasks-item-icon">
                      {iconsTasks[elem.icon]}
                    </div>
                    <div className="tasks-item-label">
                      <span>{elem.label}</span>
                    </div>
                    <div className="tasks-item-description">
                      <p className="description-text">{elem.description}</p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};
